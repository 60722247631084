<template lang="pug">
.select-input(:class="{ active: modelValue, error: hasErrors }")
  select(:value="modelValue", @input="emitChange", v-bind="$attrs")
    slot

  span.label {{ label }}
  span.error {{ errorMessage }}

  svg.arrow(width="24", height="24", viewBox="0 0 24 24", fill="none")
    path(d="M7.406 7.828l4.594 4.594 4.594-4.594 1.406 1.406-6 6-6-6z")

</template>
<script setup lang="ts">
import { computed } from 'vue'

import type { PropType } from 'vue'
import type { ErrorObject } from '@vuelidate/core'
import type { TextMask } from '~/scripts/masks/masks'

const props = defineProps({
  modelValue: { type: [String, Number] },
  label: String,
  errors: Object as PropType<ErrorObject[]>,
  mask: Function as PropType<TextMask>
})

const emit = defineEmits(["update:modelValue"])

const hasErrors = computed(() => (props.errors?.length ?? 0) > 0);
const errorMessage = computed(() => hasErrors.value ? props.errors?.[0].$message : "" );

const emitChange = (event: Event) => {
  const value = (event.target as HTMLInputElement).value;
  const maskedValue = props.mask ? props.mask(value) : value;

  emit("update:modelValue", maskedValue);

  if (props.modelValue == maskedValue) {
    (event.target as HTMLSelectElement).value = maskedValue;
  }
}
</script>
<style lang="sass" scoped>
@import '~/assets/styles/variables.sass'

.select-input
  position: relative
  display: inline-block
  height: 56px
  padding: 16px 0
  box-sizing: border-box
  width: 100%

  select
    height: 24px
    width: 100%
    padding: 0 4px
    box-sizing: border-box
    border: 0
    border-bottom: 2px solid $beige
    border-radius: 0
    outline: none
    transition: border-bottom-color .16s ease-in
    font-family: $primary-font-family
    font-size: 14px
    appearance: none
    -webkit-appearance: none
    background-color: rgba(255, 255, 255, 0)

    &:required, &:invalid
      box-shadow: none

    &:-webkit-autofill
      -webkit-box-shadow: 0 0 0 40px white inset !important

    &[disabled]
      background-color: rgba(239, 239, 239, 0.3)
      border-bottom-color: #F5F5F5

    &:focus
      border-bottom-color: $magenta

      & ~ span.label
        transform: translateY(-14px) scale(0.8)

  span.label
    position: absolute
    top: 16px
    left: 0
    font-family: $primary-font-family
    font-size: 14px
    font-style: italic
    color: $labelColor
    -webkit-user-select: none
    -moz-user-select: none
    -ms-user-select: none
    user-select: none
    pointer-events: none
    transform-origin: top left
    transform: translateY(0) scale(1)
    transition: transform .16s ease-in-out, color .16s ease-in-out

  span.error
    position: absolute
    top: 32px
    left: 0
    font-size: 12px
    color: $errorColor
    opacity: 0
    transform: translateY(0)
    transition: opacity .16s ease-in-out, transform .16s ease-in-out

  svg.arrow
    position: absolute
    top: 14px
    right: 8px
    fill: $labelColor
    pointer-events: none

  &.active
    span.label
      transform: translateY(-14px) scale(0.8)

  &.error
    select
      border-bottom-color: $errorColor

    span.label
      color: $errorColor

    span.error
      opacity: 1
      transform: translateY(8px)
</style>
